import React from "react";
import { Link } from "@reach/router";
import Loader from "react-loader-advanced";
import LoadingSpin from "react-loading-spin";
import {
  ApiDocs,
  Organization
} from "@civicactions/data-catalog-components";
import config from "../../assets/config";
import orgs from "../../assets/publishers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Layout from "../../components/Layout";
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"


const ApiDocsSpecific = ({ id, location }) => {
  const { state } = location;
  const [item, setItem] = React.useState(state && state.dataset ? state.dataset : {});
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    if(state && state.dataset) {
      setLoading(false);
    } else {
      axios.get(`${process.env.REACT_APP_ROOT_URL}/metastore/schemas/dataset/items/${id}?show-reference-ids`)
      .then((res) => {
        setItem(res.data);
        setLoading(false);
      })
    }
  }, [id, state])


    const orgName = "publisher" in item && item.publisher.data ? item.publisher.data.name : "";
    const orgDetails = orgs.filter(org => orgName === org.name);
    const orgImage = orgDetails.length && orgDetails[0].imageUrl ? orgDetails[0].imageUrl : "";
    const orgDesc = orgDetails.length && orgDetails[0].description ? orgDetails[0].description : "";
    let renderOrg;
    if(orgDetails.length > 0 && orgDetails[0].imageUrl) {
      renderOrg = <Organization name={orgName} imageUrl={orgImage} description={orgDesc}/>;
    } else {
      renderOrg = <Organization name={orgName} description={orgDesc}/>;
    }
  return (
    <Layout title="Dataset API">
    <div className={`dc-dataset-page ${config.container}`}>
       <Loader
          backgroundStyle={{ backgroundColor: "#f9fafb" }}
          foregroundStyle={{ backgroundColor: "#f9fafb" }}
          show={loading}
          message={
            <LoadingSpin width={"3px"} primaryColor={"#007BBC"} />
          }
        >
      <div className="row">

          
          
          <div className="results-list col-md-9 col-sm-12">
            <h1>{item.title}</h1>
          </div>
          <div className="results-list col-md-3 col-sm-12">
          <Link
                to={`/dataset/${id}`}
                state={{dataset: {...item}}}
              >
            <div className="dataset-link dc-block-wrapper">
              <FontAwesomeIcon
                icon={['fas', 'arrow-left']}
                size="1x"
                aria-hidden="true"
                role="presentation"
              />
              Back to the{" "}
              
                dataset
              .
            </div>
            
            </Link>
            </div>
            <div className="results-list col-md-12 col-sm-12">
            {/* <ApiDocs
              endpoint={
                `${process.env.REACT_APP_ROOT_URL}` +
                "/metastore/schemas/dataset/items/" +
                id +
                "/docs"
              }
            /> */}
            <SwaggerUI url={
                `${process.env.REACT_APP_ROOT_URL}` +
                "/metastore/schemas/dataset/items/" +
                id +
                "/docs"
                
              }
              spec= {
                {
                	"openapi": "3.0.2",
                	"info": {
                		"title": "API Documentation",
                		"version": "Alpha"
                	},
                	"tags": [],
                	"components": {
                		"parameters": {
                			"datasetUuid": {
                				"name": "identifier",
                				"in": "path",
                				"description": "A dataset UUID",
                				"required": true,
                				"schema": {
                					"type": "string"
                				},
                				"example": "11111111-1111-4111-1111-111111111111"
                			}
                			
                		},
                		"schemas": {
                			"Dataset": {
                				"type": "object",
                				"required": ["title", "description", "identifier", "accessLevel", "modified", "keyword"],
                				"properties": {
                					"title": {
                						"type": "string"
                					},
                					"description": {
                						"type": "string"
                					},
                					"identifier": {
                						"type": "string"
                					},
                					"accessLevel": {
                						"type": "string",
                						"enum": ["public", "restricted public", "non-public"]
                					},
                					"modified": {
                						"type": "string"
                					},
                					"keyword": {
                						"type": "array"
                					}
                				}
                			}
                		}
                	},
                	"paths": {
                		"\/api\/1\/metastore\/schemas\/dataset\/items\/{UUID}": {
                			"get": {
                				"operationId": "dataset-get",
                				"summary": "Get this dataset's metadata",
                				"tags": ["Dataset"],
                				"parameters": [{
                					"name": "identifier",
                					"in": "path",
                					"description": "The dataset UUID",
                					"required": true,
                					"schema": {
                						"type": "string"
                					},
                					"example": id
                				}],
                				"responses": {
                					"200": {
                						"description": "Ok",
                						"content": {
                							"application\/json": {
                								"schema": null
                							}
                						}
                					}
                				}
                			}
                		}
                	}
                }
              }
              
              />
          </div>

      </div>
      </Loader>
    </div>
    </Layout>
  );
}

export default ApiDocsSpecific;
