import React, { useEffect, useRef } from "react";
import { Announcement } from "@civicactions/data-catalog-components";
import Layout from '../../components/Layout';
import covid19poops from "../../assets/images/covid19poops.jpg";
import logo from '../../assets/images/logo.svg';
import rose from '../../assets/images/rose.jpg';
import naughton from '../../assets/images/naughton.jpg';
import medema from '../../assets/images/medema.jpg';
import katsivelis from '../../assets/images/katsivelis.PNG';
import paraskevopoulos from '../../assets/images/paraskevopoulos.jpg';
import rachmadi from '../../assets/images/rachmadi.jpg';
import roman from '../../assets/images/roman.jpg';
import souza from '../../assets/images/souza.jpg';
import morris from '../../assets/images/morris.jpg';
import magnet from '../../assets/images/magnet.jpg';
import allan from '../../assets/images/allan.jpg';
import tariqi from '../../assets/images/tariqi.jpg';
import tsang from '../../assets/images/tsang.jpg';
import kadonsky from '../../assets/images/kadonsky.png';
import medina from '../../assets/images/medina.jpg';
import broeke from '../../assets/images/broeke.jpg';
import { Box, Typography, Tab, Tabs } from '@material-ui/core';
import Sticky from 'react-sticky-el';
import "./about.scss";
import {
  Grid,
  Container
} from "@material-ui/core";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const About = ({ path }) => {

  const missionRef = useRef(null);
  const rationaleRef = useRef(null);
  const goalsRef = useRef(null);
  const dataRef = useRef(null);
  const teamRef = useRef(null);

  const [value, setValue] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    newValue.current.scrollIntoView({ behavior: 'smooth' })
  };

  useEffect(() => {
    window.gtag('config', 'G-19L9BSYZ73', { page_title: document.title, page_path: window.location.pathname +
      window.location.search }); 
      console.log('Logged entry to ' + window.location);
  })

  return (
    <Layout title="About">
      <div className="about-heading">
        <Grid container>

          <Grid item xs={12} sm={12}>

            <p className="heading">
              <img src={logo} alt="Wastewater SPHERE"></img>
              <h1>Wastewater <span>SPHERE</span></h1>
              <div className="acronym">(<b>S</b>ARS <b>P</b>ublic <b>H</b>ealth <b>E</b>nvironmental <b>RE</b>sponse)</div>
            </p>

          </Grid>

          <Grid container xs={12} sm={12} style={{ textAlign: 'center', position: 'relative', display: 'block', width: '100%', marginBottom: '30px' }}>
            <Sticky>
              <div>
                <Tabs
                  value={value}
                  centered={true}
                  onChange={handleChange}
                  className="about-tabs"
                  indicatorColor="primary"
                  textColor="primary"
                  scrollButtons="auto"
                >
                  <Tab label="Mission &amp; Goals" value={missionRef} />
                  <Tab label="Rationale &amp; Societal Benefits" value={rationaleRef} />
                  <Tab label="Data Visualization &amp; Access" value={dataRef} />
                  <Tab label="Team" value={teamRef} />
                </Tabs>
              </div>
            </Sticky>
          </Grid>
        </Grid>

      </div>
      <div className="about-body">
        <div className="about-wrapper">


          <div className="about-background"></div>
          <Container className="about-section">

            <Grid container>
              <Grid item xs={12} sm={6} className="mission" ref={missionRef}>
                <h1>Mission</h1>

                <p>To advance environmental surveillance of sewage informing local and global efforts for monitoring and supporting public health measures to combat disease.</p>

              </Grid>
            </Grid>
          </Container>
          <div className="about-inner-wrapper" ref={goalsRef}>
            <Container>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <div className="goals-block">
                    <h2>Goals</h2>

                    <p>The goals of the Wastewater SPHERE are to serve as a global data center and provide databases (geospatial and tabular) for organizations and individuals testing for SARS-CoV-2 in wastewater and other waterways. This will include:</p>

                    <ul>
                      <li>Integration of data generated through field deployments.</li>
                      <li>Integration of data produced from high-income countries Dashboards.</li>
                      <li>Development of approaches for visualizing and presenting global data sets on the virus in sewage.</li>
                      <li>Provision of access to global data for scientific advancements.</li>
                      <li>Providing Use-Case Studies addressing the role of wastewater surveillance to support the COVID-19 response.</li>
                    </ul>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} className="donors-block" alignItems={'center'}>
                  <h2>Donors</h2>
                  W-SPHERE is being developed as part of a larger wastewater surveillance project led by PATH and is funded by the Bill &amp; Melinda Gates Foundation and the Global Innovation Fund. 
                </Grid>

              </Grid>
            </Container>
          </div>
        </div>
        <div className="about-wrapper-3">
          <Container>
            <Grid container className="rationale-block" spacing={4} ref={rationaleRef}>
              <Grid item xs={12}>
                <h2>Rationale and Societal Benefits</h2></Grid>
              <Grid item xs={12} sm={6}>
                <p>
                It is now known that SARS-CoV-2 can infect gastrointestinal glandular epithelial cells1 and is excreted in feces of infected and asymptomatic individuals (30 to 50%), those with mild symptoms as well as those severely impacted and can be found in wastewater .  Infectious SARS-CoV-2 is not very robust in stool and wastewater, and there is no epidemiological evidence of faecal-oral transmission however  the ability to detect the RNA of SARS-CoV-2 in wastewater opens up the possibility for wastewater surveillance of COVID-19. Using the same primers and PCR approaches that are used to screen individual people, environmental virology and microbiology laboratories around the world are working with wastewater utilities and professionals to monitor wastewater in order to quantify the virus,  link to community infection and support public health strategies to address the pandemic. 
                </p>


                {/* <div className="col-md-3 col-sm-12">
                    <Announcement variation="info" heading="Note">
                    <p>Update this about page before publishing.</p>
                    </Announcement>
                </div> */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <p>
                Monitoring for viruses in wastewater has been ongoing for 70 years. The SARS-CoV-2 is not considered a waterborne pathogen, however, like the global surveillance of poliovirus program, wastewater surveillance has the potential to provide a real-time view of trends in community infection while public health struggles to monitor individuals and obtain good data to support  testing, interventions (closures, masks, social distancing) and economic reopening policy decisions. The wastewater surveillance can also support vaccination implementation and tracking of variants of concern. 
                </p>
                <p>
                In addition, knowledge on virus RNA levels in sewage,  removal by wastewater treatment processes when combined with knowledge on persistence and susceptibility to disinfection  of viruses and pathogens in general  will provide both the workers at the wastewater facility and the public with greater confidence regarding the control of any potential waterborne disease and emerging pathogens.   
                </p>
              </Grid>
            </Grid>
          </Container>
          <div className="about-inner-wrapper">

            <Container>
              <Grid container spacing={3} className="data-block" ref={dataRef}>
                <Grid item xs={12} sm={12}>
                  <h2>About</h2>
                  <p>The W-SPHERE is built upon the current knowledge platform as it is directly aligned and synergistic with the Global Water Pathogen Project (GWPP) mission to provide knowledge on pathogens in sewage.  Progress on visualization for the Knowledge to Practice (K2P) is ongoing to ensure usability of the Treatment Plant Sketcher Tool and the Pathogen Flow and Mapping Tool and knowledge translation.</p>
                </Grid>
                <Grid item xs={12} sm={9}>  
                  <h3>Data Visualization and Access</h3>
                  <p>Currently, over 2600+ sites including cities and counties (regional systems) across the world are monitoring wastewater for SARS-CoV-2 or have communicated that they will be setting up for monitoring soon. Using <a href="https://www.covid19wbec.org/covidpoops19">COVIDPoops19</a> as a starting point the W-SPHERE datasets, along with their metadata and dictionaries, will be open for access and sharing across the globe in a number of reusable formats.</p>
                  <p>Working with dashboards, utilities, scientists and laboratories, our interdisciplinary team of environmental virologists and public health specialists, as well as engineering professionals have developed a global database for environmental surveillance of SARS-CoV-2.  The data will be visualized on dynamic/updated maps on a global and country scale with options to zoom in regionally at a spatial level that will protect the anonymity of the facility and enable examination of the spatial and temporal trends of SARS-CoV-2.</p>
                </Grid>
                <Grid className="poops-block" item xs={12} sm={3}>
                  <a href="https://www.covid19wbec.org/covidpoops19"><img width="150" alt="COVIDPoops19 Dashboard" src={covid19poops} /><br />See the<br />COVIDPoops19 Dashboard</a>
                </Grid>
              </Grid>
            </Container>
          </div>
          <div>
            <Container>
              <Grid className="public-health-block" item xs={12} sm={12}>
                <h3>Public health use case</h3>
                <p>Around the world, city, state, and national leaders are making decisions on how to respond to the pandemic, allocate resources, distribute vaccines and ultimately reopen communities. Sewage provides a window to view early trends in community infection, earlier and more objective than other surveillance systems and has the potential to assist in these decisions. This could benefit society greatly.</p>
                <p>One of the goals of Wastewater SPHERE is to help governmental bodies and/or health authorities understand the added value of wastewater surveillance on a local, regional, and national level. Using interactive story maps, a series of use cases that have successfully utilized wastewater surveillance -alongside their respective health programs- will highlight the role of wastewater surveillance in the global COVID-19 response. We have the ambition to showcase good examples from around the globe. The user will have the option to select use cases based on specific characteristics such as the system setting (e.g. sewered, non-sewered system) or use case type (e.g. city level, national level, buildings, hospitals, etc.). The story map will provide a closer look at the different steps (e.g. design, monitoring, and execution), the provision of samples by Wastewater Treatment Plants, the analysis of the samples by the laboratory, the interpretation of the results in the local context, and the stakeholder participation, particularly the collaboration between the health and water authorities, as well as the challenges and lessons learned of such an endeavor.</p>
              </Grid>
            </Container>
          </div>
          <div className="about-wrapper-3">
            <Container>
              <Grid container className="team-block" spacing={2} ref={teamRef}>
                <Grid item xs={12} sm={12}>
                  <h2>Meet the Team</h2>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="map-block">
                    <a target="_blank" href="https://www.waterpathogens.org/network/network?combine=&group-id=2420" className="layer">
                      <h4>View the GWPP Network map</h4>
                    </a>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} className="background-block">
                  <p><b>PATH</b>, <b>Michigan State University</b>, <b>KWR Water Research Institute</b>, <b>University of California Merced</b> and <b>Venthic Technologies</b> are collaborating to develop the W-SPHERE data center.</p>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={4} style={{ marginBottom: "30px" }}>
                    <Grid item xs={12} sm={6} xs={12}>
                      <Grid container className="person">
                        <Grid sm={4} item>
                          <img src={rose} alt="Joan B. Rose" />
                        </Grid>
                        <Grid xs item>
                          <p><em>Prof. Joan B. Rose</em> holds the Homer Nowlin Chair in Water Research at Michigan State University in the Depts of Fisheries &amp; Wildlife and Plant, Soil and Microbiological Science. She is the winner of the 2016 Stockholm Water Prize. She is a member of the National Academy of Engineering and a Fellow of the American Academy of Microbiology.&nbsp; She is the 2001 recipient of the Clarke Water Prize and in 2015 was awarded Honorary Citizenship in Singapore for her contributions to water quality, water education and Singapore&rsquo;s water security 4-taps program.&nbsp;</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} xs={12} >
                      <Grid container className="person">
                        <Grid sm={4} item>
                          <img src={medema} alt="Gertjan Medema" />
                        </Grid>
                        <Grid xs item>
                          <p><em>Prof.&nbsp; Gertjan Medema (M)</em> is principal microbiologist at KWR since 1996. He is part-time chair on Water &amp; Health at Delft University of Technology (Sanitary Engineering) since 2009 and Visiting Hannah Professor on Water &amp; Health at Michigan State University since 2018. He is advisor of WHO and the European Commission. He has coordinated the joint research program of the Netherlands water utilities.</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} xs={12} >
                      <Grid container className="person">
                        <Grid sm={4} item>
                          <img src={naughton} alt="Colleen Naughton" />
                        </Grid>
                        <Grid xs item>
                          <p><em>Assistant Prof. Colleen Naughton </em> is an Assistant Professor in Civil and Environmental Engineering at the University of California Merced. Her research group co-designs sustainable and culturally sensitive Food-Energy-Water Systems for the UnderServed (FEWS-US). Dr. Naughton is a co-author of several sanitation technology chapters of the GWPP. She was principal investigator on a COVID-19 seed grant that developed the first global dashboard of wastewater monitoring sites and twitter account, COVIDPoops19: https://arcg.is/1aummW. Her group now participates in W-SPHERE taskforce to develop a global data center for wastewater and SARS-CoV-2 monitoring.</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} xs={12} >
                      <Grid container className="person">
                        <Grid sm={4} item>
                          <img src={katsivelis} alt="Panagis Katsivelis" />
                        </Grid>
                        <Grid xs item>
                          <p><em>Panagis Katsivelis</em> is a data and tech consultant and the founder of Venthic Technologies. He is an expert in layering and implementing advanced data and knowledge management platforms. He has been involved as a software engineer and technical manager in EU-based and other international initiatives, supporting knowledge representation and digital community building activities, but also the software development lifecycle.</p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ marginBottom: '30px' }}>
                    <Grid item xs={12} sm={2}>
                      <div className="person">
                        <img src={souza} alt="Nishita D'Souza" />
                        <h5>Nishita D' Souza<span>Michigan State University</span></h5>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <div className="person">
                        <img src={rachmadi} alt="Andri Rachmadi" />
                        <h5>Andri Rachmadi<span>Michigan State University</span></h5>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <div className="person">
                        <img src={allan} alt="Vajra Allan" />
                        <h5>Vajra Allan<span>PATH</span></h5>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <div className="person">
                        <img src={magnet} alt="Sophie Magnet" />
                        <h5>Sophie Magnet<span>PATH</span></h5>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <div className="person">
                        <img src={morris} alt="Doug Morris" />
                        <h5>Doug Morris<span>PATH</span></h5>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <div className="person">
                        <img src={tsang} alt="Amanda Tsang" />
                        <h5>Amanda Tsang<span>PATH</span></h5>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <div className="person">
                        <img src={paraskevopoulos} alt="Sotirios Paraskevopoulos" />
                        <h5>Sotirios Paraskevopoulos<span>KWR</span></h5>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <div className="person">
                        <img src={broeke} alt="Marieke ten Broeke" />
                        <h5>Marieke ten Broeke<span>KWR</span></h5>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <div className="person">
                        <img src={roman} alt="Fernando Roman" />
                        <h5>Fernando Roman<span>University of California Merced</span></h5>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <div className="person">
                        <img src={tariqi} alt="Arianna Tariqi" />
                        <h5>Arianna Tariqi<span>University of California Merced</span></h5>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <div className="person">
                        <img src={medina} alt="Clara Medina" />
                        <h5>Clara Medina<span>University of California Merced</span></h5>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <div className="person">
                        <img src={kadonsky} alt="Krystin Kadonsky" />
                        <h5>Krystin Kadonsky<span>University of California Merced</span></h5>
                      </div>
                    </Grid>
                    
                  </Grid>


                </Grid>

                {/* <Grid container className="tac">
                  <Grid item xs={12} sm={6}>
                    <h3>Technical Assistance Committee</h3>
                    <table>
                      <tbody>
                        <tr>
                          <th>
                            <p>Name</p>
                          </th>
                          <th>
                            <p>Organization</p>
                          </th>
                        </tr>
                        <tr>
                          <td>
                            <p>Dr. J. Scott Meschke</p>
                          </td>
                          <td>
                            <p>University of Washington</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Dr. Maureen Taylor</p>
                          </td>
                          <td>
                            <p>University of Pretoria</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Dr. Susan Petterson</p>
                          </td>
                          <td>
                            <p>Water &amp; Health Pty Ltd / Griffith University School of Medicine</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Dr. St&eacute;phane Vouillamoz</p>
                          </td>
                          <td>
                            <p>Novel-T S&agrave;rl</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Dr. Lukas von Tobel</p>
                          </td>
                          <td>
                            <p>Novel-T S&agrave;rl</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Dr. Paul R. Hunter</p>
                          </td>
                          <td>
                            <p>University of East Anglia, Norwich School of Medicine</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Dr. Charles (Chuck) Haas</p>
                          </td>
                          <td>
                            <p>Drexel University</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Dr. Ousmane M. Diop&nbsp;</p>
                          </td>
                          <td>
                            <p>WHO, GPLN</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Dr. Javier Martin&nbsp;</p>
                          </td>
                          <td>
                            <p>National Institute for Biological Standards and Control (NIBSC), Division of Virology</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Dr. Brian Pecson</p>
                          </td>
                          <td>
                            <p>Trussell Technologies</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Christian Walder&nbsp;</p>
                          </td>
                          <td>
                            <p>Asian Development Bank (ADB)</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Jean-Martin Brault</p>
                          </td>
                          <td>
                            <p>World Bank</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Kate Medlicott</p>
                          </td>
                          <td>
                            <p>WHO</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Pritha Hariram</p>
                          </td>
                          <td>
                            <p>Dutch Entrepreneurial Development Bank</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Anjana Seshadri</p>
                          </td>
                          <td>
                            <p>Biotechnology Industry Research Assistance Council (BIRAC) and</p>
                            <p>Grand ChAllanges India (GCI)&nbsp;</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Dr. Shirshendu&nbsp;Mukherjee&nbsp;</p>
                          </td>
                          <td>
                            <p>Biotechnology Industry Research Assistance Council (BIRAC) and</p>
                            <p>Grand ChAllanges India (GCI)&nbsp;</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <h3>SARS-CoV-2 Environmental Surveillance Working Group</h3>
                    <table>
<tbody>
<tr>
<th>
<p><strong>Name (by study site)</strong></p>
</th>
<th >
<p><strong>Organization</strong></p>
</th>
</tr>
<tr>
<td colspan="2" className="country-cell">
<p><strong>Nepal</strong></p>

</td>
</tr>
<tr>
<td>
<p>Dev Raj Joshi</p>
</td>
<td >
<p>Tribhuvan University</p>
</td>
</tr>
<tr>
<td>
<p>Samendra Sherchan</p>
</td>
<td >
<p>Tulane University</p>
</td>
</tr>
<tr>
<td>
<p>Bhushan Raj Shrestha</p>
</td>
<td >
<p>Environment and Public Health Organization</p>
</td>
</tr>
<tr>
<td>
<p>Reshma Tuladhar</p>
</td>
<td >
<p>Tribhuvan University</p>
</td>
</tr>
<tr>
<td colspan="2" className="country-cell">
<p><strong>Pakistan</strong></p>

</td>
</tr>
<tr>
<td>
<p>Imran Nisar</p>
</td>
<td >
<p>Aga Khan University</p>
</td>
</tr>
<tr>
<td>
<p>Furqan Kabir</p>
</td>
<td >
<p>Aga Khan University</p>
</td>
</tr>
<tr>
<td>
<p>Momin Kazi</p>
</td>
<td >
<p>Aga Khan University</p>
</td>
</tr>
<tr>
<td>
<p>Amyn Malik</p>
</td>
<td >
<p>Yale Institute for Global Health</p>
</td>
</tr>
<tr>
<td>
<p>Nadia Ansari</p>
</td>
<td >
<p>Aga Khan University</p>
</td>
</tr>
<tr>
<td>
<p>Windy Tanner</p>
</td>
<td >
<p>Yale University</p>
</td>
</tr>
<tr>
<td>
<p>Fatima Aziz</p>
</td>
<td >
<p>Aga Khan University</p>
</td>
</tr>
<tr>
<td>
<p>Fyezah Jehan</p>
</td>
<td >
<p>Aga Khan University</p>
</td>
</tr>
<tr>
<td colspan="2" className="country-cell">
<p><strong>Ghana</strong></p>

</td>
</tr>
<tr>
<td>
<p>Christine Moe</p>
</td>
<td >
<p>Emory University</p>
</td>
</tr>
<tr>
<td>
<p>Pengbo Liu</p>
</td>
<td >
<p>Emory University</p>
</td>
</tr>
<tr>
<td>
<p>Yuke Wang</p>
</td>
<td >
<p>Emory University</p>
</td>
</tr>
<tr>
<td>
<p>Habib Yakubu</p>
</td>
<td >
<p>Emory University</p>
</td>
</tr>
<tr>
<td colspan="2" className="country-cell">
<p><strong>Bangladesh</strong></p>

</td>
</tr>
<tr>
<td>
<p>Mami Taniuchi</p>
</td>
<td >
<p>University of Virginia</p>
</td>
</tr>
<tr>
<td>
<p>Isobel Blake</p>
</td>
<td >
<p>Imperial College London</p>
</td>
</tr>
<tr>
<td colspan="2" className="country-cell">
<p><strong>Indonesia</strong></p>

</td>
</tr>
<tr>
<td>
<p>Julie Bines</p>
</td>
<td >
<p>University of Melbourne</p>
</td>
</tr>
<tr>
<td>
<p>Amanda Handley</p>
</td>
<td >
<p>Murdoch Children&rsquo;s Research Institute</p>
</td>
</tr>
<tr>
<td>
<p>Indah Kartika Murni</p>
</td>
<td >
<p>Gadjah Mada University</p>
</td>
</tr>
<tr>
<td>
<p>Vicka Oktaria</p>
</td>
<td >
<p>Gadjah Mada University</p>
</td>
</tr>
<tr>
<td>
<p>Dave McCarthy</p>
</td>
<td >
<p>Monash University</p>
</td>
</tr>
<tr>
<td>
<p>Celeste Donato</p>
</td>
<td >
<p>Murdoch Children&rsquo;s Research Institute</p>
</td>
</tr>
<tr>
<td colspan="2" className="country-cell">
<p><strong>Malawi</strong></p>

</td>
</tr>
<tr>
<td>
<p>Kayla Barnes</p>
</td>
<td >
<p>Harvard School of Public Health and Broad Institute</p>
</td>
</tr>
<tr>
<td>
<p>Nicholas Feasey</p>
</td>
<td >
<p>Liverpool School of Tropical Medicine</p>
</td>
</tr>
<tr>
<td>
<p>Catherine Anscombe</p>
</td>
<td >
<p>Malawi-Liverpool-Wellcome Trust Clinical Research Programme</p>
</td>
</tr>
<tr>
<td>
<p>Shannon McSweeney</p>
</td>
<td >
<p>Liverpool School of Tropical Medicine</p>
</td>
</tr>
<tr>
<td colspan="2" className="country-cell">
<p><strong>India</strong></p>

</td>
</tr>
<tr>
<td>
<p>Venkata Raghava Mohan</p>
</td>
<td >
<p>Christian Medical College, Vellore</p>
</td>
</tr>
<tr>
<td>
<p>Dilip Abraham</p>
</td>
<td >
<p>Christian Medical College, Vellore</p>
</td>
</tr>
<tr>
<td colspan="2" className="country-cell">
<p><strong>Brazil</strong></p>

</td>
</tr>
<tr>
<td>
<p>Adriana Cristina da Silva Nunes</p>
</td>
<td >
<p>Federal University of Rond&ocirc;nia Foundation</p>
</td>
</tr>
<tr>
<td>
<p>Najla Benevides Matos</p>
</td>
<td >
<p>Oswaldo Cruz Foundation (Fiocruz)</p>
</td>
</tr>
<tr>
<td colspan="2" className="country-cell">
<p><strong>Kenya</strong></p>

</td>
</tr>
<tr>
<td>
<p>Frederic B&eacute;en</p>
</td>
<td >
<p>KWR Water Research Institute</p>
</td>
</tr>
<tr>
<td>
<p>Jesse Gitaka</p>
</td>
<td >
<p>Mount Kenya University</p>
</td>
</tr>
<tr>
<td colspan="2" className="country-cell">
<p><strong>South Africa</strong></p>

</td>
</tr>
<tr>
<td>
<p>Gina Pocock</p>
</td>
<td >
<p>Waterlab (Pty) Ltd</p>
</td>
</tr>
<tr>
<td colspan="2" className="country-cell">
<p><strong>Tunisia</strong></p>

</td>
</tr>
<tr>
<td>
<p>Fatma Guerfali</p>
</td>
<td >
<p>Institut Pasteur de Tunis</p>
</td>
</tr>
</tbody>
</table>

                    <p>&nbsp;</p> */}
                  {/* </Grid> */}
                {/* </Grid> */}
              </Grid>
            </Container>
          </div>
        </div>
      </div >
    </Layout >
  )
};

export default About;
