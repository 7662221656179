import {applyMiddleware, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import rootReducer from './reducers';

export default function configureStore (preloadedState) {
    let store;
    if (process.env.NODE_ENV === 'production') {
        store = createStore(rootReducer, preloadedState, applyMiddleware(thunkMiddleware));
    } else {
        const composedStore = composeWithDevTools(applyMiddleware(thunkMiddleware));
        store = createStore(rootReducer, preloadedState, composedStore);
    }

    return store;
}
