import React, {useState, useEffect} from "react";
import {
  Grid,
  Container
} from "@material-ui/core";
import { Database, Map, MapPin } from 'react-feather';
import './stats.scss';
import {fetch_stats} from "../../redux/stats/stats_reducer";
import {useDispatch, useSelector} from "react-redux";
import {
  getDataPointStat,
  getDatasetStat,
  getSiteStat,
  getStats,
  getStatsStatus
} from "../../redux/stats/stats_selector";

const DONE = 'done'

const StatBlock = () => {
  const dispatch = useDispatch()
  const datasetStat = useSelector(getDatasetStat)
  const siteStat = useSelector(getSiteStat)
  const dataPoint = useSelector(getDataPointStat)
  const status = useSelector(getStatsStatus)

  useEffect(() => {
      dispatch(fetch_stats())
  }, []);

  return (
    <div className={`stats`}>
      {(status === DONE) &&
      <Container>
        <Grid container spacing={8} justify="center">
          <Grid item md={12}>
          <h3>Wastewater SPHERE at a glance:</h3>
          </Grid>
          <Grid item sm={4} xs={12}>
            <div className={`statsBlock`}>
              <Database size={40}/>
              <h4><span>{datasetStat}</span> Datasets</h4>
            </div>
          </Grid>
          <Grid item sm={4} xs={12}>
            <div className={`statsBlock`} >
              <Map size={40}/>
              <h4><span>{siteStat}</span> Sites</h4>
            </div>
          </Grid>
          <Grid item sm={4} xs={12}>
            <div className={`statsBlock`} >
              <MapPin size={40}/>
              <h4><span>{dataPoint}</span> Countries</h4>
            </div>
          </Grid>
        </Grid>
      </Container>
    }
    </div>
  );
};

export default StatBlock;
