import React, {useEffect} from 'react';
import Layout from '../../components/Layout';
import Map from '../../components/Map';
import './map.scss';
import {
  Container, Grid
} from "@material-ui/core";
import NewMap from "../../components/GlobalMap/NewMap";
import {useDispatch} from "react-redux";
import {set_object_one} from "../../redux/map/map_reducer";

const GlobalMap = () => {
  // const dispatch = useDispatch()
  // useEffect(()=>{
  //   //dispatch(set_object_one('panagis'))
  // }, [])
  const urlParams = new URLSearchParams(window.location.search);
          const embed = urlParams.get('embed');
          if (embed !== null && embed === 'true') {
            
            return <Map embed={true}/>
          }
          else {
  return (
    <Layout title="Map">
      <Map embed={false}/>
    </Layout>
  );
}
}

export default GlobalMap;
