import React, {useEffect} from 'react';
import { useLocation, Link  } from "@reach/router"
import { defaultFacets, normalizeItems, sortOptions } from '../../config/search';
import { Search, SearchSidebar, SearchContent } from "@civicactions/data-catalog-components";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Skeleton from '@material-ui/lab/Skeleton';
import Layout from "../../components/Layout";
import config from "../../assets/config";
import { DataGrid } from '@material-ui/data-grid';
import Papa from "papaparse";
import link from '../../assets/images/link.svg'; 
import database from '../../assets/images/database.svg'; 
import {
  Grid,
  Container,
  Tabs,
  Tab
} from "@material-ui/core";
import "./search.scss";

const getMode = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const mode = urlParams.get('mode');
  return mode && mode === 'sources' ? true: false;
}

const SearchTemplate = ({path}) => {

  useEffect(() => {
    window.gtag('config', 'G-19L9BSYZ73', { page_title: document.title, page_path: window.location.pathname +
      window.location.search }); 
      console.log('Logged entry to ' + window.location);
  })
  
  const location = useLocation();

  const handleChange = (event, newValue) => {
    var queryString = '';
    if (newValue === 1) {
      const sources = Papa.parse(`https://sphere.waterpathogens.org/data/sources/csv`, {
            download: true,
            header: true,
            complete: function(results) {
              setSources(results.data.map((row, i) => {row.id = i+1; return row}));
              setValue(1);
          }
      })
      queryString = '?mode=sources';
    }
    else {
      queryString = '';
    }
    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + queryString;
    window.history.pushState({path:newurl},'',newurl);
    setValue(newValue);
  };

  useEffect(() => {
    const sourcesActive = getMode();
    if (sourcesActive) {
      setSourcesLoading(true);
      const sources = Papa.parse(`https://sphere.waterpathogens.org/data/sources/csv`, {
            download: true,
            header: true,
            complete: function(results) {
              setSources(results.data.map((row, i) => {row.id = i+1; return row}));
              setValue(1);
              setSourcesLoading(false);
          }
      })
    }
    
  }, []);
  
  const [value, setValue] = React.useState(0);
  const [sources, setSources] = React.useState([]);
  const [sourcesLoading, setSourcesLoading] = React.useState(false);
  
  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'Title',
      headerName: 'Title',
      width: 400,
    },
    {
      field: 'Country',
      headerName: 'Country',
      width: 150,
    },
    {
      field: 'Website',
      headerName: 'Website',
      width: 150,
    },
      {
        field: "actions",
        headerName: "Data access",
        sortable: false,
        filterable: false,
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ cursor: "pointer" }}
            >
              <a target="_blank" href={params.row.Website}><img src={link}/></a>
              {(params.row['JSON Metadata'] !== '') ? [<span style={{color: '#333333'}}>&nbsp;|&nbsp;</span>,
                <a target="_blank" href={'/dataset/'+JSON.parse(params.row['JSON Metadata']).identifier}><img src={database}/></a>]:
                <></>

              }
              
            </div>
          );
        }
      }
        // `${params.getValue(params.id, 'Website') || ''} ${
        //   params.getValue(params.id, 'JSON Metadata') || ''
        // }`,
  ];

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        className={`dc-page ${config.container}`} 
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div>{children}
            </div>
        )}
      </div>
    );
  }

  return (
    <Layout title="Search">
      <div className="data-section">
      <Container>

<Grid container>
  <Grid item xs={12} sm={6} className="mission">
  <div className="data-background"></div>
    <h1>Data</h1>
    <p>View and access the list of dashboards (<b>data sources</b>) from monitoring programs globally. Browse and download the open data harvested by W-SPHERE.</p>
    
    <Grid container className={'cta'} spacing={2}>
      <Grid item sm={12} xs={12}>
      <Link className='contributeLink' to={'/contribute'}>
           <div className="dc-block-wrapper">
             <img src={database}/> Contribute your data
           </div>
           </Link>
        
      </Grid>
      
    </Grid>
    
  </Grid>
  
  <Grid item xs={12} sm={6} alignContent={'center'} className="mission">
  
  </Grid>
</Grid>
<Grid container>
  <Grid item sm={12} xs={12}>
  <Tabs
          value={value}
          centered={true}
          onChange={handleChange}
          className="about-tabs"
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          fullWidth={true}
          >
          <Tab label="Browse Open Datasets" value={0} />
          <Tab label="View online data sources" value={1} />
        </Tabs>
  </Grid>
</Grid>

</Container>
    <TabPanel value={value} index={0}>
        <Grid container>
          <Grid item sm={12} xs={12}>
            <h2>Datasets</h2>
          </Grid>
        </Grid>
        <Search
          searchEndpoint={`${process.env.REACT_APP_ROOT_URL}/search`}
          defaultFacets={defaultFacets}
          sortOptions={sortOptions}
          setSearchUrl={false}
          path={path}
          location={location}
          normalize={normalizeItems}
        >
        {/* <Link onClick={() => {location.search = '?sort=title'}} to="/search?sort=title">Title</Link> */}
          <div className="row">
            <SearchContent />
            <SearchSidebar />
          </div>
        </Search>
      </TabPanel>
      <TabPanel className={`dc-page ${config.container}`} value={value} index={1}>
        
      <h2>Data Sources</h2>
        { (sources.length && !sourcesLoading) ?
          <div style={{ height: 1200, width: '100%', marginBottom: '50px' }}> 
             <DataGrid
              rows={
               sources
              }
              columns={columns}
              pageSize={50}
              disableSelectionOnClick
            />
          </div>:<div style={{paddingBottom:50}}>
            <Skeleton variant="text" height={40}/>
            <Skeleton variant="text" height={40}/>
            <Skeleton variant="text" height={40}/>
            <Skeleton variant="text" height={40}/>
            <Skeleton variant="text" height={40}/>
          </div>
          // <Alert severity="error">
          //   <AlertTitle>Error</AlertTitle>
          //   An unexpected error has ocurred — <strong>please try again later!</strong>
          // </Alert>
        }
      </TabPanel>
      </div>
      </Layout>
  );
}

export default SearchTemplate;
