import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const fetch_stats = createAsyncThunk('fetch_stats', async () => {
    return new Promise((resolve, reject) => {
        fetch("https://sphere.waterpathogens.org/data/stats/json").then(res => {
            res.json().then(res => {resolve(res)}).catch(err => reject(err));
        })
    })

})

const INITIAL = 'initial'
const LOADING = 'loading'
const DONE = 'done'
const ERROR = 'error'

const statsReducerSlice = createSlice({
    name: 'stats',
    initialState: {
        stats: null,
        status: INITIAL,
        errors: []
    },
    reducers: {
    },
    extraReducers: {
        [fetch_stats.pending]: (state, action) => {
            state.status = LOADING
        },
        [fetch_stats.fulfilled]: (state, action) => {
            state.stats = action.payload
            state.status = DONE
        },
        [fetch_stats.rejected]: (state, action) => {
            state.errors.push(action.error)
            state.status = ERROR
        }
    }
})

//export const { } = statsReducerSlice.actions
export default statsReducerSlice.reducer
export const obj = statsReducerSlice