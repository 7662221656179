import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-zoom';


const genData = (props) => { 
  const timeseries = props.timeseries.map( conc => parseInt(conc));
    const timestamps = props.timestamps.map(stamp => { 
        var date = new Date(stamp);
        var day = ''+date.getFullYear() + '-'+ (date.getMonth()+1) + '-' + date.getDate(); 
        return day;
    });
    const target =props.target;
  return ({
  labels: timestamps,
  datasets: [
    {
      label: target+' concentrations in '+props.units[0],
      data: timeseries,
      backgroundColor: timeseries.map((point, i) => { return (i <= timeseries.length - 6) ? '#42919E' : 'rgba(0,51,71, 0.7)'}),
      borderColor: timeseries.map((point, i) => { return (i <= timeseries.length - 6) ? '#42919E' : '#003347'}),
      borderWidth: 1,
    },
  ],
})
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
  plugins: {
    zoom: {
      zoom: {
        enabled: true,
        mode: 'x',
      },
      pan: {
        enabled: true,
        mode: 'x',
      }
    }
  }
  
};

const SamplesChart = (props) => {
  const [data, setData] = useState(genData(props));

  useEffect(() => {
    setData(genData(props));

  }, []);



  return (
    <>
      
      <Bar data={data} options={options} />
    </>
  );
}


export default SamplesChart;