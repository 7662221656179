import React, {useEffect} from "react";
import {
  Grid,
  Container,
  Button
} from "@material-ui/core";
import './globaldataset.scss';
import gwpp from '../../assets/images/gwpp.png';
import { Link } from "@reach/router";

const GlobalDatasetBlock = () => {

  
  return (<Container>
     <div className={`globaldataset`}>
     <div className='globalcontainer'>
        <Grid container spacing={8} justify="center">
          <Grid item sm={9} xs={12}>
            <h3><span>New</span>W-SPHERE Global Dataset now available!</h3>
            <p>
              
            </p>
          </Grid>
          <Grid item sm={3} xs={12}>
            <a href='/form/contribute?subject=global'><Button className={`globaldatasetButton`} style={{
              padding: '9px 24px 10px',
              background: '#033347',
              border: '3px solid rgb(52, 143, 166)',
              borderRadius: '8px',
              fontFamily: 'Open sans',
              color: 'white',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '14px',
              margin: 'auto',
              marginTop: '20px',
              textDecoration: 'none',
              lineHeight: '150%'}}>Request it here
            </Button>
            </a>
          </Grid>
          
        </Grid>
        </div>
      </div>
      </Container>
  );
};

export default GlobalDatasetBlock;
