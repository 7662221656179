import React from 'react';
import { Router } from "@reach/router";
import Home from './templates/home';
import About from './templates/about';
import Contribute from './templates/contribute';
import UseCases from './templates/studies';
import SearchTemplate from './templates/search';
import ApiDocsFull from './templates/api';
import NotFound from './templates/not_found';
import Dataset from './templates/dataset';
import ApiDocsSpecific from './templates/dataset/api';
import GlobalMap from './templates/map';
import '@civicactions/data-catalog-components/dist/index.css';
import './theme/index.scss';
import {Provider} from 'react-redux'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import configureStore from "./redux";

import Frame from 'react-frame-component';

library.add(fab, fas);

function App() {
  
  const store = configureStore()
  return (
      <Provider store={store}>
        <Router>
          <NotFound default />
          <Home path="/" />
          <About path="/about"/>
          <Contribute path="/contribute"/>
          <UseCases path="/use-cases"/>
          <GlobalMap path="/map" key={Date.now()}/>
          <SearchTemplate path="/search" />
          <ApiDocsFull path="/api" />
          <Dataset path="/dataset/:id" />
          <ApiDocsSpecific path="/dataset/:id/api" />
        </Router>
      </Provider>
  );
}

export default App;
