export const types = {
  MAP_LOADED: "MAP_LOADED"
};

export const loadingStates = {
  LOADING: "loading",
  DONE: "done",
  ERROR: "error"
};

export const modes = {
  GLOBAL: "global",
  SAMPLES: "samples",
  TRENDS: "trends"
};