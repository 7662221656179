import React, {useEffect} from "react";
import {
  Grid,
  Container,
  Button
} from "@material-ui/core";
import './visit.scss';
import gwpp from '../../assets/images/gwpp.png';
import covid19poops from '../../assets/images/covid19poops.jpg';
import { Link } from "@reach/router";

const ContributeBlock = () => {

  
  return (
     <Container className={`visit`}>
          
          <Grid container  spacing={4}><Grid item xs={12}><h3>Visit also:</h3></Grid>
          <Grid item sm={6} xs={12}>
            <a href="https://www.waterpathogens.org" >
              <div className="dc-block-wrapper gwpp">
              <img src={gwpp} alt="Global Water Pathogens Project" />
              </div>
            </a>
              
          </Grid>
          <Grid item sm={6} xs={12}>
          <a href="https://ucmerced.maps.arcgis.com/apps/opsdashboard/index.html#/c778145ea5bb4daeb58d31afee389082" >
              <div className="dc-block-wrapper poops">
                
            <img src={covid19poops} alt="COVIDPoops19" style={{width: '200px'}}/>
            <p>COVIDPoops19</p>
            </div>
            </a>
          </Grid>
          </Grid>
      </Container>
  );
};

export default ContributeBlock;
