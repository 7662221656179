import React, { useState, useRef, useEffect } from "react";
import Layout from '../../components/Layout';
import mail from "../../assets/images/mail.svg";
import download from "../../assets/images/download-cloud.svg";
import { Link } from '@reach/router';
import "./contribute.scss";

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import {
  Grid,
  Container,
  Typography
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';



const Contribute = ({ path }) => {

  const missionRef = useRef(null);
  const rationaleRef = useRef(null);
  const goalsRef = useRef(null);
  const dataRef = useRef(null);
  const teamRef = useRef(null);

  const [value, setValue] = React.useState(null);

  useEffect(() => {
    window.gtag('config', 'G-19L9BSYZ73', { page_title: document.title, page_path: window.location.pathname +
      window.location.search }); 
      console.log('Logged entry to ' + window.location);
  })

  const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  
  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);
  
  const [expanded, setExpanded] = React.useState('panel1');
  
    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };


  return (
    <Layout title="Contribute">
      
      <div className="contribute-body" style={{paddingBottom: '100px'}}>
        <div className="about-wrapper">


          <div className="contribute-background"></div>
          <Container className="about-section">

            <Grid container>
              <Grid item xs={12} sm={6} className="mission" ref={missionRef}>
                <h1>Contribute</h1>
                <p>Get your work featured on the W-SPHERE Data Center by sharing your organization's data. Get started by downloading our Data Reporting Template. Learn more by reading the FAQs below. </p>
                <Grid container className={'cta'} spacing={2}>
                  <Grid item sm={6} xs={12}>
                  <a href={'/wsphere-data-template'}>
                <div className="dc-block-wrapper">
                  <img src={download}/> Get data template
                </div>
                </a>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                  <a href={'/form/contribute'}>
                <div className="dc-block-wrapper">
                  <img src={mail}/> Contact us
                </div>
                </a>
                  </Grid>
                </Grid>
                
              </Grid>
            </Grid>
          </Container>
          <div className="about-inner-wrapper" ref={goalsRef}>
            <Container>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={9}>
                  <div className="faq-block">
                    <h2>Frequently Asked Questions</h2>

                    <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="panel-header">
          <Typography>1. Why contribute my data to W-SPHERE?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          The W-SPHERE will serve as a repository for data sets all over the world with an attempt to develop a harmonized approach for retaining the historical and unprecedented monitoring of SARS-CoV-2 in wastewater.   By adding your data set this will be available to public health and scientific communities in one location with a consistent visualization.   Your dataset will be a valuable contribution to the global effort on surveying and curtailing the COVID-19 pandemic.  
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" className="panel-header">
          <Typography>2. I want to contribute my data to W-SPHERE. How should I get started?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p>Please  download our data template.  You can also contact us directly and we may set up a time to meet and assist with data submission.</p>  
<p>To start with the data template, please focus on the Data Dictionary tab first. It indicates all the fields (variables) that can be reported in the data you provide. Per field, take note of the type, format and acceptable values. Make sure to also check the available input examples, so that you can provide similar values in the rest of the data tabs.</p> 

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" className="panel-header">
          <Typography>3. My data contains sensitive information. How does W-SPHERE handle privacy issues?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          W-SPHERE is an open access resource. Sensitive information will not be displayed or disclosed.

          Additionally, we describe the terms for data sharing in our <b>W-SPHERE Data Agreement</b>. Get a copy of it <a href="https://sphere.waterpathogens.org/data-agreement" target="_blank">here</a>.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" className="panel-header">
          <Typography>4. I need help with the Data Template. How does it work?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          The Data Team at W-SPHERE is ready to help. Please <a target="_blank" href="/form/contribute?subject=contribute">contact us</a> and we will assist you with your data submission. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      
                  </div>
                </Grid>
                

              </Grid>
            </Container>
          </div>
        </div>
      </div >
    </Layout >
  )
};

export default Contribute;
