import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from '@reach/router';
import Layout from '../../components/Layout';
import config from "../../assets/config";
import ResourceTemplate from "../../components/Resource";
import Papa from "papaparse";
import { Spinner } from 'reactstrap';
import map from '../../assets/images/map.svg';
import website from '../../assets/images/link_dark.svg';
import search from '../../assets/images/search.svg';

import {
  Grid,
  Container,
  Chip
} from "@material-ui/core";
import {
  Table
} from "@civicactions/data-catalog-components";
import orgs from "../../assets/publishers";

const Dataset = ({id, location}) => {
  const { state } = location;
  const [item, setItem] = useState(state && state.dataset ? state.dataset : {})
  const [samples, setSamples] = useState([])
  const [sites, setSites] = useState([])
  const [dates, setDates] = useState([])
  const [hasWindow, checkForWindow] = useState(false);
  const [source, setSource] = useState(null);

  useEffect(() => {
      
    
      

    async function getItem() {
      
      const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/metastore/schemas/dataset/items/${id}?show-reference-ids`);
      
      setItem(data);
      window.gtag('config', 'G-19L9BSYZ73', { page_title: 'Dataset - '+data.title, page_path: window.location.pathname +
        window.location.search }); 
        console.log('Logged entry to ' + window.location);
    
      if (window !== undefined) {
        checkForWindow(true);
      }
      getDistros(data.distribution);
      getSource();
    }
    if (!state || !state.dataset) {
      getItem();
    }
    
    async function getSource() {
      const { data } = await axios.get(`https://sphere.waterpathogens.org/data/sources/dataset/${id}`);
      if (data.length) {
        setSource(data[0]);
      }
      else {
        setSource(null);
      }
    }

    async function getDistros(distributions) {
      const sitesCSV = distributions.filter(distro => { 
        return distro.data.title && distro.data.title.indexOf('Sites CSV') !== -1
      })
      
      if (sitesCSV.length) { 
        const sitesPayload = await axios.get(sitesCSV[0].data['downloadURL']);
        const parsedSites = Papa.parse(sitesPayload.data);
        setSites(parsedSites.data);
      }
      else {
        setSites([])
      }
      
      const samplesCSV = distributions.filter(distro => { 
        return distro.data.title && distro.data.title.indexOf('Samples CSV') !== -1
      })
      if (samplesCSV.length) { 
        const samplesPayload = await axios.get(samplesCSV[0].data['downloadURL']);
        const parsedSamples = Papa.parse(samplesPayload.data);
        setSamples(parsedSamples.data);

        var lookup = {};
        var result = [];
        var timeIndex = parsedSamples.data[0].indexOf('time');
        for (var item, i = 1; item = parsedSamples.data[i++];) {
          var name = item[timeIndex];
          if (!(name in lookup) && name !== undefined) {
            lookup[name] = 1;
            result.push(name);
          }
        }
        setDates(result);
      }
      else {
        setSamples([])
      }

    }
  }, [id, state]);


  const orgName =
    "publisher" in item && item.publisher ? item.publisher.data.name : "";
  const orgDetails = orgs.filter(org => orgName === org.name);
  const orgImage = orgDetails.length > 0 && orgDetails[0].imageUrl ? orgDetails[0].imageUrl : null;
  const orgDesc = orgDetails.length > 0 && orgDetails[0].description ? orgDetails[0].description : "";
  let renderOrg;
  if(orgDetails.length > 0 && orgDetails[0].imageUrl) {
    // renderOrg = <Organization name={orgName} imageUrl={orgImage} description={orgDesc}/>;
    renderOrg = <h3 className='organization'>{orgName}</h3>
  } else {
    // renderOrg = <Organization name={orgName} description={orgDesc}/>;
    renderOrg = <h3 className='organization'>{orgName}</h3>
  }


  const tag = "keyword" in item ? item.keyword : [];
  const theme = "theme" in item ? item.theme : [];


  function themes(theme) {
    if (!theme) {
      return null;
    } else {
      return theme.map(topic => {
        return (
          <div className="topic" key={topic.identifier}><img src={search}/> {topic.data}</div>
          // <TopicWrapper component={TopicIcon} topic={topic.data} key={topic.identifier}/>
        );
      });
    }
  }

  // // Process content for 'Columns in this Dataset' table.
  // // const labelsT2 = {};
  // // const valuesT2 = {};

  // // columns.forEach((value, index) => {
  // //   labelsT2[index] = { label: value };
  // //   valuesT2[index] = "String";
  // // });

  // // Process content for 'Additional Information' table.
  const labelsT3 = {};
  const valuesT3 = {};
  var geneTargets = [];

  if (orgName && orgName.length > 0) {
    labelsT3.publisher = { label: "Publisher" };
    valuesT3.publisher = orgName;
  }
  if ("identifier" in item && item.identifier) {
    labelsT3.identifier = { label: "Identifier" };
    valuesT3.identifier = item.identifier;
  }
  if ("issued" in item && item.issued) {
    labelsT3.issued = { label: "Issued" };
    valuesT3.issued = item.issued;
  }
  if ("modified" in item && item.modified) {
    labelsT3.modified = { label: "Last Update" };
    valuesT3.modified = item.modified;
  }
  if ("license" in item && item.license) {
    labelsT3.license = { label: "License" };
    valuesT3.license = `<a href="${item.license}">${item.license}</a>`;
  }
  if ("contactPoint" in item && item.contactPoint && item.contactPoint.fn) {
    labelsT3.contact = { label: "Contact" };
    valuesT3.contact = item.contactPoint.fn;
  }
  if (
    "contactPoint" in item &&
    item.contactPoint &&
    item.contactPoint.hasEmail
  ) {
    labelsT3.email = { label: "Contact E-mail" };
    valuesT3.email = `<a href="${item.contactPoint.hasEmail}">${item.contactPoint.hasEmail}</a>`;
  }
  if ("accessLevel" in item && item.accessLevel) {
    labelsT3.access = { label: "Public Access Level" };
    valuesT3.access = item.accessLevel;
  }

  if ("geneTargets" in item && item.geneTargets.length) {
    geneTargets = item.geneTargets;
  }
  if ("populationAvailable" in item && item.populationAvailable) {
    labelsT3.population = { label: "Population data available" };
    valuesT3.population = item.populationAvailable.data;
  }
  if ("landingPage" in item && item.landingPage) {
    labelsT3.homepage = { label: "Homepage URL" };
    valuesT3.homepage = `<a href="${item.landingPage}">${item.landingPage}</a>`;
  }

  

  return (
    <Layout title={`Dataset - ${item.title}`} description={item.description}>
      <Container className={`dc-dataset-page ${config.container}`}>
       <Grid container spacing={5}>
         <Grid item xs={12} sm={9} className='header'>
           <div className='header-breadcrumb'></div>
           <div className='header-title'>
                  <h1>{item.title}</h1>
                  
                  {renderOrg} 
                  {theme.length > 0 && <div className="dc-item-theme">&nbsp;- {themes(theme)}</div>}
            </div>
         </Grid>
         <Grid item xs={12} sm={3} className='map-link'>
         
           <Link to={'/map?id='+item.identifier}>
           <div className="dc-block-wrapper">
             <img src={map}/> View on Map
           </div>
           </Link>
           {(source !== null && source.website !== '') && <a className="website" target="_blank" href={source.website}>
           <div className="dc-block-wrapper">
             <img src={website}/> View website
           </div>
           </a>}
         </Grid>
         <Grid item xs={12} md={3}>
         
         
         {(item) && [
           (sites.length > 0 ) &&
              <div className="sites dc-block-wrapper">
                <h2><span className="icon"></span><span className="number">{sites.length -1}</span> <span className="text">monitored site(s)</span></h2>    
              </div>
          
          ,(samples.length > 0 ) &&
              <div className="samples dc-block-wrapper">
                <h2><span className="icon"></span><span className="number">{samples.length -1}</span> <span className="text">collected samples</span></h2>    
              </div>
          ,
          (dates.length > 0 ) &&
              <div className="dates dc-block-wrapper">
                <h2><span className="icon"></span><span className="number">{dates.length}</span> <span className="text">dates sampled</span></h2>    
              </div>
          , 
          (geneTargets.length > 0 ) &&
              <div className="genes dc-block-wrapper">
                <h2><span className="number">{geneTargets.map((target, i) => <span>{target.data}{(i !== geneTargets.length-1) && <span>,</span> }</span>)}</span> <span className="text">gene targets</span></h2>    
              </div>
         ]}
        <div className="notice dc-block-wrapper">
           <p><em>Notice:</em> W-SPHERE serves as a global center for data and public health use cases on SARS-CoV-2 in wastewater, and is not designed to take the place of the local dashboards. Please use the links provided to the local data/dashboard to view the most up to date local information.</p>
         </div>
         <div className="citation dc-block-wrapper">
           <p><h5>How to cite</h5>{item.title}: Wastewater SPHERE (<span>date of download</span>). https://sphere.waterpathogens.org/dataset/{item.identifier}.</p>
         </div>
         <div className="dc-block-wrapper">
                  The information on this page is also available via the{" "}
                  <Link
                    to={`/dataset/${item.identifier}/api`}
                    state={{ dataset: {...item} }}
                  >
                    API
                  </Link>.
                </div>
         </Grid>
         <Grid item xs={12} md={9}>
         
         {Object.keys(item).length
                ?(
                <div className='body'>
                  <h2>Description</h2>
                <p className='description' >{item.description}</p>
                {(hasWindow && item.distribution && item.accessLevel !== 'non-public') &&
                <>
                <h2>Files</h2>
                
                  {item.distribution.map(dist => {
                    return <ResourceTemplate key={dist.identifier} resource={dist} identifier={dist.identifier} />;
                  })}
                  </>
                }
                <div className="tags">
                  
                <h2>Tags</h2>
                {tag.map(tag => {return <Link to={"/search?keyword="+tag.data}><Chip clickable key={tag.identifier} size='medium' className="tag" label={tag.data} /></Link>})}
                
                </div>
                <div className="metadata-wrapper">
                  <h2>Metadata</h2>
                <Table
                  configuration={labelsT3}
                  data={valuesT3}
                  tableclass="metadata"
                />
                </div></div>
                ):( <div className="row">
                <Spinner color="primary" />
              </div>
            )}
         </Grid>
       </Grid>
            
      </Container>
      
      </Layout>
  );
};

export default Dataset;
