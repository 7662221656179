import React, {useEffect} from 'react';
import config from "../../assets/config";
import Layout from "../../components/Layout";
import { ApiDocs } from "@civicactions/data-catalog-components";

const ApiDocsFull = ({ path }) => {
  useEffect(() => {
    window.gtag('config', 'G-19L9BSYZ73', { page_title: document.title, page_path: window.location.pathname +
      window.location.search }); 
      console.log('Logged entry to ' + window.location);
  })
return (
  <Layout title="API Documentation">
  <div className={`dc-page ${config.container}`}>
    <div className="page-content">
      {typeof window !== `undefined` && (
        <ApiDocs endpoint={process.env.REACT_APP_ROOT_URL} />
      )}
    </div>
  </div>
  </Layout>
)
};

export default ApiDocsFull;
