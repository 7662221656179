import React from "react";
import axios from 'axios';
import Layout from '../../components/Layout';
import StatBlock from '../../components/StatBlock';
import ContributeBlock from '../../components/ContributeBlock';
import GlobalDatasetBlock from '../../components/GlobalDatasetBlock';
import VisitBlock from '../../components/VisitBlock';
import copy from "../../assets/copy.json";
import { Link } from '@reach/router';
import Map from '../../components/Map';
import '../map/map.scss';
import {
  Grid,
  Button,
  Container
} from "@material-ui/core";

const Home = () => {
  const [datasets, setDatasets] = React.useState(null);
  const [themes, setThemes] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [fDatasets, setFDatasets] = React.useState([])


  React.useEffect(() => {
      window.gtag('config', 'G-19L9BSYZ73', { page_title: document.title, page_path: window.location.pathname +
        window.location.search }); 
        console.log('Logged entry to ' + window.location);
    async function getDatasets() {
      const {data} = await axios.get(`${process.env.REACT_APP_ROOT_URL}/metastore/schemas/dataset/items?show-reference-ids`)
      setDatasets(data);
    }
    async function getThemes() {
      const {data} = await axios.get(`${process.env.REACT_APP_ROOT_URL}/metastore/schemas/theme/items`)
      setThemes(data);
    }
    if (datasets === null) {
      getDatasets()
      getThemes();
    }
    if (datasets) {
      const orderedDatasets = datasets.sort(function(a,b) {
        return a.title - b.title;
      });

      setFDatasets(orderedDatasets.length > 3 ? orderedDatasets.slice(orderedDatasets.length -3, orderedDatasets.length) : orderedDatasets);
    }
  }, [datasets])

  React.useEffect(() => {
    setItems(themes.map(x => {
      let item = {
        identifier: x.identifier,
        ref: `search?theme=${x.data}`,
        title: x.data,
        size: "100"
      };
      return item;
    }))
  }, [themes])

  return (
    <Layout title="Home">
        <div className="home-page">
        {/* <Hero title={copy.hero[0].title} intro={copy.hero[0].intro} gradient={'rgb(22, 46, 81), rgb(9, 120, 188)'} /> */}
        <div className="container">
            {/* <IconList
                items={items}
                component={IconListItem}
                paneTitle="Dataset Topics"
                className="opendata-icon-list"
            /> */}
        </div>
        <Container style={{marginBottom:'30px'}}>
          <div className="map-front">
            <div className="map-overlay">
              <h2>A global data center by the <a href="https://www.waterpathogens.org">Global Water Pathogens Project</a></h2>
              <p>Advancing environmental surveillance of sewage to inform local and global efforts for monitoring and supporting public health measures to combat disease.</p>
              <Link to='/about'><Button style={{
                padding: '9px 24px 10px',
                background: '#fff',
                color: '#42919E',
                borderRadius: '8px',
                fontFamily: 'Open sans',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '14px',
                margin: 'auto',
                marginTop: '20px',
                textTransform: 'none',
                lineHeight: '150%',
                position:'absolute',
                bottom:'40px',
                left: '40px'
                }}>Read more
              </Button>
              </Link>
              <Link to='/map'><Button className={`mainButton`} style={{
                padding: '9px 24px 10px',
                background: '#BD5E07',
                borderRadius: '8px',
                fontFamily: 'Open sans',
                color: 'white',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '14px',
                margin: 'auto',
                marginTop: '20px',
                textTransform: 'none',
                lineHeight: '150%',
                position:'absolute',
                bottom:'40px',
                right: '40px'
                }}>See global map
              </Button>
              </Link>
            </div>
            <Map referer={'home'}/>
          </div>
        </Container>
        <ContributeBlock/>
        <GlobalDatasetBlock/>
        <VisitBlock/>
        <StatBlock/>
        </div>
    </Layout>
  );
}

export default Home;
