import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {types, loadingStates, modes} from "../../config/types";

export const fetch_from_server = createAsyncThunk('name', async ({param}) => {

})

export const datasetFetchStarted = createAsyncThunk('load_dataset', async (id) => {

    return new Promise((resolve, reject) => {
        fetch("https://sphere.waterpathogens.org/data/stats/json").then(res => {
            res.json().then(res => {resolve(res)}).catch(err => reject(err));
        })
    })

})



const mapReducerSlice = createSlice({
    name: 'map',
    initialState: {
        loaded: false,
        datasetStatus: loadingStates.done,
        errors: [],
        config: {
            portalItem: {
                id: "ba5a7589831a4914b649ca79a07dfeac"
            },
            zoom: 2,
            center: [-46.807, 32.553]
        },
        availableTimeExtent: null,
        activeTimeExtent: null,
        view: null,
        selectedFeature: null,
        selectedDataset: null,
        activeLayer: null,
        selectedMode: modes.GLOBAL
    },
    reducers: {
        mapLoaded: (state, action) =>  {
            return {
                ...state,
                loaded: true,
                view: action.payload,
            }
        },
        featureSelected: (state, action) => {
            var feature = action.payload;
            
            return {
                ...state,
                selectedFeature: action.payload
            }
            
        },
        datasetSelected: (state, action) => {
            return {
                ...state,
                selectedDataset: action.payload,
                selectedMode: modes.SAMPLES
            }
        },
        modeReset: (state, action) => {
            return {
                ...state,
                selectedDataset: null,
                selectedFeature: null,
                selectedMode: modes.GLOBAL
            }
        },
        modeChanged: (state, action) => {
            return {
                ...state,
                selectedMode: action.payload
            }
        },
        timeExtentChanged: (state, action) => {
            return {
                ...state,
                activeTimeExtent: {
                    start: action.payload.start,
                    end: action.payload.end
                },
            }
        },
        layerRendered: (state, action) => {
            
            return {
                ...state,
                availableTimeExtent: {
                    start: action.payload.start,
                    end: action.payload.end
                },
                activeLayer: action.payload.id
            }
        },
        layerRemoved: (state, action) => {
            return {
                ...state,
                activeLayer: null
            }
        },
    },
    extraReducers: {
        [datasetFetchStarted.pending]: (state, action) => {
            state.datasetStatus = loadingStates.LOADING
        },
        [datasetFetchStarted.fulfilled]: (state, action) => {
            state.selectedDataset = action.payload.id
            state.datasetStatus = loadingStates.DONE
        },
        [datasetFetchStarted.rejected]: (state, action) => {
            state.errors.push(action.error)
            state.datasetStatus = loadingStates.ERROR
        }
    }
})

export const { mapLoaded, featureSelected, datasetSelected, modeReset, modeChanged, layerRendered, layerRemoved, timeExtentChanged } = mapReducerSlice.actions
export default mapReducerSlice.reducer