import React, {useState, useEffect} from "react";
import Helmet from "react-helmet";
import { Link } from '@reach/router';
import {
  AppBar,
  Toolbar,
  List,
  Grid,
  Container,
  IconButton,
  Drawer
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { ChevronRight } from 'react-feather';
import navLinks from "../../assets/menu.json";
import logo from '../../assets/images/logo.svg';
import { makeStyles } from "@material-ui/core/styles";
import PartnersBlock from '../PartnersBlock';


const useStyles = makeStyles({
  navbar: {
      backgroundColor: 'transparent',
      color: '#003347',
      height: '120px',
      paddingTop: '30px',
      '& a' : {
        color: '#003447'
      }
  },
  logoTxt: {
    float: 'left',
    textTransform: 'lowercase',
    fontWeight: 500,
    fontFamily: 'Open Sans',
    color:'gray',
    textTransform: 'uppercase',
    fontSize:'20px',
    display:'inline-block',
    marginLeft:'10px',
    color: '#216272',
    '& span': {
      display: 'block',
      color: '#003347',
      fontSize:'35px',
      fontWeight: 900,
    }
  },
  logoTxtMobile: {
    float: 'left',
    textTransform: 'lowercase',
    fontWeight: 500,
    fontFamily: 'Open Sans',
    color:'gray',
    textTransform: 'uppercase',
    fontSize:'17px',
    display:'inline-block',
    marginLeft:'10px',
    color: '#216272',
    '& span': {
      fontSize:'30px',
      display: 'block',
      color: '#003347',
      fontWeight: 800
    }
  },
  logoImg: {
    float: 'left',
    display:'inline-block',
    width: '60px'
  },
  logoImgMobile: {
    float: 'left',
    display:'inline-block',
    width: '40px'
  },
  navbarDisplayFlex: {
      display: `block`
  },
  navDisplayFlex: {
      display: `block`,
      float:'right',
      marginTop: '10px'
  },
  linkTextMobile: {
    width:'100%',
    minWidth: '300px',
    display: 'block',
    textDecoration: `none`,
      textTransform: `uppercase`,
      fontFamily: 'Open Sans',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '60px',
      textAlign: 'center',
      height:'60px',
      color: '#003347',
      '&:hover': {
        textDecoration: `none`,
        background: '#fff',
        borderRadius: '16px',
        boxShadow: '0px 4px 4px rgba(66, 145, 158, 0.15)',
        transition: '0.5s'
      }
  },
  linkTextMobileFeatured: {
    width:'100%',
    minWidth: '300px',
    display: 'block',
    textDecoration: `none`,
      textTransform: `uppercase`,
      fontFamily: 'Open Sans',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '60px',
      textAlign: 'center',
      height:'60px',
      color: 'rgb(189, 94, 7)',
      '&:hover': {
        textDecoration: `none`,
        background: '#fff',
        borderRadius: '16px',
        boxShadow: '0px 4px 4px rgba(66, 145, 158, 0.15)',
        transition: '0.5s'
      }
  },
  linkSoonMobile: {
    width: '100%',
    display: 'block',
    textAlign: 'center',
    minWidth: '300px',
    textDecoration: `none`,
    textTransform: `uppercase`,
    fontFamily: 'Open Sans',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '60px',
    height:'60px',
    color: '#ccc',
    '&:hover': {
      textDecoration: `none`,
      background: '#efefef',
      borderRadius: '3px',
      transition: '0.5s'
    }
  },
  linkText: {
      textDecoration: `none`,
      textTransform: `uppercase`,
      fontFamily: 'Open Sans',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '16px',
      marginLeft: '10px',
      marginRight: '10px',
      padding:'14px',
      height:'20px',
      color: '#003347',
      '&:hover': {
        textDecoration: `none`,
        background: '#fff',
        borderRadius: '16px',
        boxShadow: '0px 4px 4px rgba(66, 145, 158, 0.15)',
        transition: '0.5s'
      }
  },
  linkTextFeatured: {
    textDecoration: `none`,
    textTransform: `uppercase`,
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16px',
    marginLeft: '10px',
    marginRight: '10px',
    padding:'14px',
    height:'20px',
    color: '#003347',
    background: '#fff',
    borderRadius: '16px',
    boxShadow: '0px 4px 4px rgba(66, 145, 158, 0.15)',
    '&:hover': {
      color: '#003347',
      textDecoration: `none`
    }
},
  linkSoon: {
    textDecoration: `none`,
    textTransform: `uppercase`,
    fontFamily: 'Open Sans',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px',
    marginLeft: '10px',
    marginRight: '10px',
    padding:'14px',
    height:'20px',
    color: '#ccc',
    '&:hover': {
      textDecoration: `none`,
      background: '#efefef',
      borderRadius: '3px',
      transition: '0.5s'
    }
  },
  mobileMenu: {
    width: '1.5em',
    height: '1.5em'
  },
  logoMobile: {
    position: 'absolute',
    right: '10px'
  },
  footer: {
    marginTop: 'auto',
    backgroundColor: '#003347',
    color: '#42919e',
    minHeight: '100px',
    fontSize: '14px',
    '& a:hover': {
        color: '#42919e',
    }
  },
  footerText: {
      color: '#42919e',
      fontSize: '14px'
  },
  footerLinks: {
      color: '#42919e',
      fontSize: '14px',
      textAlign: 'right',
      position: 'absolute',
      bottom:'30px',
      right:'20px',
      fontWeight: 700
  },
  footerBlocks: {
    paddingTop:'40px',
    position: 'relative'
  },
  footerCopyright: {
    color:'#fff',
    marginTop: '100px',
    marginBottom: '30px',
    '& a': {
      fontWeight: 700,
      color: '#fff'
    }
  }
});
const Layout = ({
  children,
  title,
  description
}) => {

  const classes = useStyles();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false
  })

  const { mobileView, drawerOpen } = state;

  const displayMobile = () => {
    const handleDrawerOpen = () => setState((prevState) => ({ ...prevState, drawerOpen: true }));
    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen
          }}
        >
          <MenuIcon className={classes.mobileMenu}/>
        </IconButton>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: () => setState((prevState) => ({ ...prevState, drawerOpen: false }))
          }}
        >
          <div>
            <List
                component="nav"
                aria-labelledby="main navigation"
                className={classes.navDisplayFlex}
              >
              {navLinks.main.map((link) => {
                  return (!link.disabled) ?
                  (!link.featured) ?
                  <Link to={link.url} key={link.label} onClick={() => {if (window.location.pathname === link.url ) { window.location.href=link.url}}}  target={link.target} className={classes.linkTextMobile}>{link.label}</Link>:
                  <Link to={link.url} key={link.label} onClick={() => {if (window.location.pathname === link.url ) { window.location.href=link.url}}}  target={link.target} className={classes.linkTextMobileFeatured}>{link.label}</Link>
                  :<span title="Coming soon" className={classes.linkSoonMobile}>{link.label}</span>
                })}
            </List>  
          </div>
        </Drawer>
        <Link to='/' className={classes.logoMobile}> 
          <img className={classes.logoImgMobile} src={logo}/><h1 className={classes.logoTxtMobile}>Wastewater<span>SPHERE</span></h1>
        </Link>
      </Toolbar>
    );
  };

  const displayDesktop = () => {
    return (
      <Toolbar>
          <Container maxWidth="lg" fixed className={classes.navbarDisplayFlex}>
            <Link to='/'>
            <img className={classes.logoImg} src={logo}/><h1 className={classes.logoTxt}>Wastewater<span>SPHERE</span></h1>
            
            </Link>
            <List
              component="nav"
              aria-labelledby="main navigation"
              className={classes.navDisplayFlex}
            >
              {navLinks.main.map((link) => {
                  return (!link.disabled) ?
                  (!link.featured) ?
                  <Link to={link.url} key={link.label} onClick={() => {if (window.location.pathname === link.url ) { window.location.href=link.url}}} target={link.target} className={classes.linkText}>{link.label}</Link>:
                  <Link to={link.url} key={link.label} onClick={() => {if (window.location.pathname === link.url ) { window.location.href=link.url}}} target={link.target} className={classes.linkTextFeatured}>{link.label}</Link>
                  :<span key={link.label} title="Coming soon" className={classes.linkSoon}>{link.label}</span>

                })}
            </List>
          </Container>
        </Toolbar>
    );
  };

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  return (
    <div className="App">
      <Helmet
        title={`${title} - Wastewater SPHERE`}
        description={description}
        generator="Wastewater SPHERE - Global Water Pathogens Project"
        defer={false}
        htmlAttributes={{
          "lang": "en"
        }}
      />
      <AppBar position="static" className={classes.navbar} elevation={0}>
      {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
      <main>
        {children}
      </main>
      <footer className={classes.footer}>
        <Container>
            <Grid container className={classes.footerBlocks}>
              <Grid md={10} xs={12} item>
                <PartnersBlock/>
                <Container className="disclaimer">
            <p>W-SPHERE is being developed as part of a larger wastewater surveillance project led by PATH and is funded by the Bill & Melinda Gates Foundation and the Global Innovation Fund.</p>
        </Container>
                <Container className={classes.footerCopyright}>
                  Copyright &copy;&nbsp; 
                  <a color="inherit" href="https://www.waterpathogens.org/">
                    Global Water Pathogens Project
                  </a>&nbsp;{new Date().getFullYear()}
                </Container>
              </Grid>
              <Grid md={2} xs={12} className={classes.footerLinks} item>
              
                  <a style={{color:'#42919e'}} href='/admin/dashboard' target="_blank">Admin area <ChevronRight/></a>
              </Grid>
            </Grid>
            
          {/* <Typography className={classes.footerText}>My sticky footer can be found here.</Typography> */}
          
        </Container>
      </footer>
    </div>
  );
};

export default Layout;
