export const presenceSym = {
    type: "simple-marker", // autocasts as new SimpleLineSymbol()
    color: "#CC7A88",
    width: 1,
    style: "circle",
    outline: {
      width:0.5,
      color: '#003347'
    },
    opacity: 0.6
  };

  
export const absenceSym = {
    type: "simple-marker", // autocasts as new SimpleLineSymbol()
    color: "#78B33E",
    width: 1,
    style: "circle",
    outline: {
      width:0.5,
      color: '#003347'
    },
    opacity: 0.6
  };
  
export const otherSym = {
    type: "simple-marker", // autocasts as new SimpleLineSymbol()
    color: "#ccc",
    style: "circle",
    outline: {
      width:0.5,
      color: '#003347'
    },
  };