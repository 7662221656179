import {createSelector} from "@reduxjs/toolkit";

const getStatsState = store => store.stats
export const getStats = store => (getStatsState(store) ? getStatsState(store).stats : null)
export const getStatsStatus = store => (getStatsState(store) ? getStatsState(store).status : null)
export const getStatsErrors = store => (getStatsState(store) ? getStatsState(store).errors : null)

export const getDatasetStat = createSelector(
    [getStats], (stats) => {
        if (stats) return stats.filter(stat => stat.type==="Dataset")[0]["count"]
        else return []
    }
)

export const getSiteStat = createSelector(
    [getStats], (stats) => {
        if (stats) return stats.filter(stat => stat.type==="Site")[0]["count"]
        else return []
    }
)

export const getDataPointStat = createSelector(
    [getStats], (stats) => {
        if (stats) return stats.filter(stat => stat.type==="Data Point")[0]["countries"]
        else return []
    }
)

