import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import Layout from '../../components/Layout';
import editForm from "../../assets/images/edit-form.svg";
import download from "../../assets/images/download-cloud.svg";
import { Link } from '@reach/router';
import "./studies.scss";

import Skeleton from '@material-ui/lab/Skeleton';
import {
  Grid,
  Container,
  Typography
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';



const UseCases = ({ path }) => {

  const missionRef = useRef(null);
  const [studies, setStudies] = useState([]);

  

  useEffect(() => {
    window.gtag('config', 'G-19L9BSYZ73', { page_title: document.title, page_path: window.location.pathname +
      window.location.search }); 
      console.log('Logged entry to ' + window.location);
      
      async function fetchStudies() {
        const { data } = await axios.get(`https://sphere.waterpathogens.org/data/use-cases`);
        console.log(data);
        setStudies(data);
      }
      
      if (studies.length === 0) {
        fetchStudies();
      }
  })

  return (
    <Layout title="Use Cases" description="Our goal is to help health authorities understand the added value of wastewater surveillance, by showcasing good examples of how wastewater data have been used to support public health actions in different settings (national, regional, local, building-level surveillance; high and low resource settings; sewered and non-sewered settings). If you have a good use case, please contact us or get started by downloading our Use Case Template.">
      
      <div className="contribute-body">
        <div className="about-wrapper">


          <div className="studies-background"></div>
          <Container className="about-section">

            <Grid container>
              <Grid item xs={12} sm={6} className="mission" ref={missionRef}>
                <h1>Use cases</h1>
                <p>Have your wastewater data been used to underpin public health actions?</p>
                <span>Our goal is to help health authorities understand the added value of wastewater surveillance, by showcasing good examples of how wastewater data have been used to support public health actions in different settings (national, regional, local, building-level surveillance; high and low resource settings; sewered and non-sewered settings).</span>
                
                
              </Grid>
                  
          {(studies.length > 0) ?<Grid container spacing={4} className="cases-inner-wrapper">
                <Grid item xs={12} sm={12}>
                  <h2>Published use cases</h2>
                </Grid>
                
                {studies.map( (study) => <Grid item xs={12} sm={4}>
                  <a href={study.field_website[0].uri} target="_blank">
                    <div className="dc-block-wrapper">
                      <h3>{study.title[0].value}</h3>
                      <span className="study-organization">{study.field_address[0].organization}</span>
                      <span className="study-location">{study.field_address[0].country_code} - {study.field_address[0].address_line1}</span>
                    </div>
                  </a>
                  </Grid>)}
                  </Grid>:<Container><div style={{paddingBottom:50}}>
                  <Skeleton variant="text" height={40}/>
                  <Skeleton variant="text" height={40}/>
                  <Skeleton variant="text" height={40}/>
                  <Skeleton variant="text" height={40}/>
                  <Skeleton variant="text" height={40}/>
                </div></Container>
                }
                
                

            </Grid> 
            <div className="submit-wrapper contribute">
            <Grid container className={'cta'} spacing={2}>
            <Grid item sm={12} xs={12}>
            <h3>Submit your case study to the Wastewater SPHERE</h3>
            <p>
            If you have a good use case, please contact us or get started by downloading our Use Case Template.
            </p>
          </Grid>
                  <Grid item sm={6} xs={12}>
                  <a href={'/wsphere-use-case-template'}>
                <div className="dc-block-wrapper">
                  <img src={download}/> Get Use Case Template
                </div>
                </a>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                  <a target="_blank" href={'/form/contribute?subject=use-case'}>
                <div className="dc-block-wrapper">
                  <img src={editForm}/> Fill-in the Online Form
                </div>
                </a>
                  </Grid>
                </Grid>
              </div>
          </Container>
        </div>
      </div >
    </Layout >
  )
};

export default UseCases;
