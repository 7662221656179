import React, {useEffect} from "react";
import {
  Grid,
  Container,
  Button
} from "@material-ui/core";
import './contribute.scss';
import gwpp from '../../assets/images/gwpp.png';
import { Link } from "@reach/router";

const ContributeBlock = () => {

  
  return (
     <Container className={`contribute`}>
        <Grid container spacing={8} justify="center">
          <Grid item sm={9} xs={12}>
            <h3>Contribute to the Wastewater SPHERE data center</h3>
            <p>
              Contributing data to SPHERE can help you get started with standardized data reporting and licensing, while creating your own space for visualizations and analysis. 
            </p>
          </Grid>
          <Grid item sm={3} xs={12}>
            <Link to='/contribute'><Button className={`contributeButton`} style={{
              padding: '9px 24px 10px',
              background: '#BD5E07',
              borderRadius: '8px',
              fontFamily: 'Open sans',
              color: 'white',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '14px',
              margin: 'auto',
              marginTop: '20px',
              textDecoration: 'none',
              lineHeight: '150%'}}>Contribute
            </Button>
            </Link>
          </Grid>
          
        </Grid>
      </Container>
  );
};

export default ContributeBlock;
