import React, { Component } from "react";
import ReactECharts from 'echarts-for-react';  // or var ReactECharts = require('echarts-for-react');

function stampToDate(stamp) {
  var date = new Date(stamp);
  var day = '' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  return day
}


const SamplesChart3 = ({ timeseries, timestamps, target, units, graphic }) => {
  const options = {
    grid: { top: 0, right: 8, bottom: 0, left: 8, containLabel: true },
    title: {
      top: -15,
      left: 'left',
      subtext: target + ' concentrations in ' + units[0],
      subtextStyle: {
        fontWeight: 'bold',
        color: '#303030'
      }
    },
    xAxis: {
      type: 'category',
      data: timestamps.map(stamp => {
        return stampToDate(stamp);
      }),
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        inside: false,
        formatter: '{value}\n'
      }
    },
    series: [
      {
        data: timeseries.map(conc => parseInt(conc)),
        type: 'bar',
        itemStyle: {
          color: (val) => {
              if (val.dataIndex < timeseries.length - 5) {
                return '#42919E'
              } else {
                return '#003347'
              }
            }
        }
      },
    ],
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none'
        },
        restore: {},
        saveAsImage: {}
      }
    },
    tooltip: {
      trigger: 'axis',
    }
  };

  return <div><ReactECharts style={{width: '360px', height: '220px' }} option={options} /></div>;
};

export default SamplesChart3;