
import chevron from '../assets/images/chevron-right.svg';
import link from '../assets/images/link.svg';

export const viewSitesAction = {
    title: "View sites",
    id: "view-sites",
    className: 'viewSitesBtn',
    image: chevron
  };

export const viewWebsiteAction = {
    title: "Website",
    id: "view-website",
    className: 'viewWebsiteBtn',
    image: link
};